import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { getInitialLocale } from "../i18n/utils";

const Index = (): JSX.Element => {
  const router = useRouter();

  useEffect(() => {
    router.replace("/[lang]", `/${getInitialLocale()}`);
  });

  return (
    <>
      <Head>
        <meta key="robots" name="robots" />
      </Head>
    </>
  );
};

export default Index;
